<template>
<div>
    <!-- Navbar -->
    <nav class="navbar navbar-color" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                        <img src="../../../public/logo.png" width="50" height="50">
                        <h1 class="heading">Square<span>Garden</span></h1>
                    </router-link>
                <span class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarHomepage" @click="toggleNavmenu">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </div>
            <div id="navbarHomepage" class="navbar-menu" :class="mobile">
                <div class="navbar-start">
                    <div class="navbar-item">
                        <button id="installbutton" class="button is-success is-inverted install-button hide"><i class="fas fa-download"></i>installieren</button>
                        <span id="iOSInstall" class="hide">To install press <img src="../../../public/img/add-to-homescreen.png" alt="add-to-home-screen" class=ios> and add to Homescreen</span>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <span class="link"><router-link to="/product" style="color: black;">{{ $t("nav.product.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <span class="link"><router-link to="/contact" style="color: black;">{{ $t("nav.contact.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <div class="buttons">
                            <router-link to="/login" class="button is-success is-inverted"><i class="far fa-user"></i>Login</router-link>
                            <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("nav.register.value")}}</router-link>
                            <languageSwitcher />
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </nav>
    <section class="hero is-info">
    <div class="hero-body container">
        <p class="title">
            {{ $t("login.hero.heading")}}
        </p>
        <p class="subtitle">
            {{ $t("login.hero.text")}}
        </p>
    </div>
    </section>
    <div class="container align-center">
        
        <br>
        <br>
        <!-- Login Form -->
        <form class="field">
            <label for="email" class="label">{{ $t("login.email.value")}}</label>
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                </span>
                <input id="email-field" type="email" v-model="email" class="input" required />
                <p class="help is-danger">{{ email_error }}</p>
            </div>
            <label for="login-password" class="label">{{ $t("login.password.value")}}</label>
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
                <input type="password" id="login-password" v-model="password" class="input" required />
            </div>
            <p class="help is-danger">{{ auth_error }}</p>
            <button class="button is-info top-margin" @click="login">{{ $t("login.button.value")}}</button>
            <!-- <div class="forgot-pw-container">
                <a href="#" class="forgot-pw">Forgot Password</a>
            </div> -->
        </form>
    </div>
</div>
        
</template>

<script>
import { loginWithEmail } from "../../db/auth.js";
import languageSwitcher from "@/components/multilang/languageSwitcher";

export default {
    name: 'Login',
    components: {
        languageSwitcher,
    },
    data() {
        return { 
            email_error: "",
            email: "",
            auth_error: "",
            password: "",
            mobile: "",
        }
    },
    methods: {
        validateEmail(email) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                return false;
            } else {
                return true;
            }
        },
        login(e) {
            e.preventDefault();

            const email_field = document.getElementById("email-field");
            if(this.validateEmail(this.email)){
                //validirung der email
                email_field.classList.add("is-danger");
                this.email_error = "Not a valid email!";
            } else {
                //deleting errors
                email_field.classList.remove("is-danger");
                this.email_error = "";
                
                //login prosses
                loginWithEmail(this.email, this.password)
                .then(res => {
                    if(res.type === "success"){
                        this.$router.push("/dashboard");
                    } else {
                        this.auth_error = res.message;
                    }
                })
            }
        },
        //UI Methods
        toggleNavmenu(e) {
            //Toggling the burger-menu
            if(!e.target.classList.contains("is-active")){
                e.target.classList.add("is-active")
                this.mobile="is-active";
            } else {
                e.target.classList.remove("is-active");
                this.mobile="";
            }
        }
    }
}
</script>

<style scoped>
.navbar-color {
    background: #94C973;
}

h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
}

.link{
    font-size: 1.1rem;
    font-weight: 500;
}

i{
    margin-right: 5px;
}

img.ios {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.hide {
    visibility: hidden;
}

/* AB HIER ÄNDERN*/

.align-center {
    margin: 0 auto;
}

.top-margin {
    margin-top: 15px;
}

@media screen and (max-width: 1023px) {
    .align-center {
        margin: 0 20px;
    }
}

</style>